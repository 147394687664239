<template>
  <router-view></router-view>
</template>

<script>

import trackingServices from '@/plugins/posthog';

export default {
  name: 'edoc-perso',
  mounted() {
    trackingServices.getPostHogInstance();
  },
};
</script>
