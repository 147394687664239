export default {
  SET_USER(state, data) {
    // eslint-disable-next-line
    state.user = data;
  },
  SET_AdminDocs(state, data) {
    // eslint-disable-next-line
    state.adminDocs = data;
  },
  SET_Services(state, data) {
    // eslint-disable-next-line
    state.services = data;
  },
  SET_USAGE_DATAS(state, data) {
    // eslint-disable-next-line
    state.usageDatas = data;
  },
  SET_AdminDocID(state, data) {
    // eslint-disable-next-line
    state.adminDocid = data;
  },
  SET_PetalCounter(state, data) {
    // eslint-disable-next-line
    state.petalCounter = data;
  },
  SET_DematStatus(state, data) {
    // eslint-disable-next-line
    state.dematStatus = data;
  },
};
