

export default {
  snackbar: {
    message: undefined,
    active: undefined,
    centered: undefined,
    type: undefined,
  },
  sideMenu: {
    opened: false,
  },
  breadcrumbs: [],
  loading: {
    display: false,
    count: 0,
  },
};
