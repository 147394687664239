import Vue from 'vue';
import userCollectorApi from '@/api/UserCollectorApi';
import collectorApi from '@/api/CollectorApi';
import trackingServices from '@/plugins/posthog';

export default {
  getCollectors({ dispatch, commit }) {
    dispatch('application/startLoading', null, { root: true });
    return collectorApi
      .findAll()
      .then((data) => {
        commit('SET_COLLECTORS', data.content.listCollectors);
        return data.content.listCollectors;
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.get.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
        commit('SET_COLLECTORS', []);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  // Vue.$i18n.t('collector.get.error'),
  getUserCollectors({ dispatch, commit }) {
    dispatch('application/startLoading', null, { root: true });
    return userCollectorApi
      .findAll()
      .then((data) => {
        commit('SET_USER_COLLECTORS', data.content.listCollectors);
        return data.content.listCollectors;
      })
      .catch(() => {
        commit('SET_USER_COLLECTORS', []);
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.get.mine.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
        commit('SET_COLLECTORS', []);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  addSubscription({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return userCollectorApi
      .add(params.providerId, params.inputs)
      .then((data) => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.subscription'),
            type: 'success',
            time: 5,
            centered: true,
          },
          { root: true },
        );
        trackingServices.collectorActivated();
        return data;
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.subscription error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  updateSubscription({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return userCollectorApi
      .update(params.providerId, params.subscriptionId, params.inputs)
      .then((data) => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.update'),
            type: 'success',
            time: 5,
            centered: true,
          },
          { root: true },
        );
        return data;
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.update.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  timeoutSubscription({ commit }, subscriptionId) {
    commit('TIMEOUT_SUBSCRIPTION', subscriptionId);
    setTimeout(() => commit('TIMEOUT_SUBSCRIPTION_REMOVE'), 2 * 60 * 1000);
  },

  sendAuthCode({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return userCollectorApi
      .sendAuthCode(params.subscriptionId, params.code)
      .then((data) => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.authentification'),
            type: 'success',
            time: 5,
            centered: true,
          },
          { root: true },
        );
        return data;
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.authentification.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  deleteSubsciption(store, id) {
    store.dispatch('application/startLoading', null, { root: true });
    return userCollectorApi
      .deleteById(id)
      .then((data) => {
        store.dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.account.delete'),
            type: 'success',
            time: 5,
            centered: true,
          },
          { root: true },
        );
        return data;
      })
      .catch(() => {
        store.dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.account.delete.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        store.dispatch('application/finishLoading', null, { root: true });
      });
  },
  EmptyCollectors({ commit }) {
    commit('SET_USER_COLLECTORS', []);
    commit('SET_COLLECTORS', []);
  },

  loadFiles({ dispatch }, params) {
    dispatch('application/startLoading', null, { root: true });
    return userCollectorApi
      .loadFiles(params.providerId, params.subscriptionIds)
      .then((data) => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.loadFiles.success'),
            type: 'success',
            time: 5,
            centered: true,
          },
          { root: true },
        );
        return data;
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.loadFiles.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  getSubscriptionsForProvider({ dispatch, commit }, params) {
    dispatch('application/startLoading', null, { root: true });
    return userCollectorApi
      .getProviderSubscriptions(params.providerId)
      .then((data) => {
        commit('UPDATE_USER_COLLECTORS_PROVIDER_SUBSCRIPTION', { providerId: params.providerId, subscriptions: data.content.subscriptions });
        return data.content.subscriptions;
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('collector.subscription error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
};
