import posthog from 'posthog-js';
import UserInfoApi from '@/api/UserInfoApi';
import auth from '@/utils/auth';

const trackingServices = {

  getPostHogInstance() {
    const apiHost = 'https://eu.posthog.com';
    auth.getConf().then((response) => {
      posthog.init(response.CONF.TOKEN_POSTHOG, {
        api_host: apiHost,
        autocapture: false,
        capture_pageview: false,
        capture_pageleave: false,
      });
      if (!posthog.get_distinct_id().includes('@')) {
        UserInfoApi.getUserInfo().then((info) => {
          posthog.identify(info.content.email.toLowerCase());
        });
      }
    });
    return posthog;
  },

  logout() {
    posthog.capture('User Logout');
    posthog.reset();
  },

  documentViewed() {
    posthog.capture('Document Viewed');
  },

  documentShared(duration) {
    posthog.capture('Document Shared', {
      duration,
    });
  },

  documentAdded() {
    posthog.capture('Document Added');
  },

  collectorActivated() {
    posthog.capture('Collector Activated');
  },

  passwordStocked() {
    posthog.capture('Password Stocked');
  },

};

export default trackingServices;
