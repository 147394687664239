import Vue from 'vue';
import downloadjs from 'downloadjs';
import api from '@/api/UserInfoApi';
import petalsApi from '@/api/PetalsApi';

export default {
  getUserInfo({ dispatch, commit }) {
    dispatch('application/startLoading', null, { root: true });
    return api
      .getUserInfo()
      .then((data) => {
        commit('SET_USER', data.content);
      })
      .catch(() => {
        commit('SET_USER', {});
        // Vue.$i18n.t('profil.getuser.error'),
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profil.getuser.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  setAdminDocId({ commit }, data) {
    commit('SET_AdminDocID', data.content);
  },
  // petalsApi.getCounter().then((response) => {
  //   this.n = response.content.counter;
  // });
  setPetalsCounter({ dispatch, commit }) {
    dispatch('application/startLoading', null, { root: true });
    return petalsApi
      .getCounter()
      .then((response) => {
        commit('SET_PetalCounter', response.content);
      })
      .catch(() => {
        commit('SET_PetalCounter', 0);
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profil.petals.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  setDematStatus({ dispatch, commit }) {
    dispatch('application/startLoading', null, { root: true });

    return api
      .getPaySlipAgreementStatus()
      .then((response) => {
        commit('SET_DematStatus', response.content.edpAgreementStatus);
      })
      .catch(() => {
        commit('SET_DematStatus', {});
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profil.dematStatus.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  getUsageDatas({ dispatch, commit }) {
    dispatch('application/startLoading', null, { root: true });
    return api
      .getUsageDatas()
      .then((response) => {
        commit('SET_USAGE_DATAS', response.content);
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  getIdentityDocs({ dispatch, commit }) {
    dispatch('application/startLoading', null, { root: true });
    return api
      .getIdentityDocs()
      .then((data) => {
        commit('SET_AdminDocs', data.content.edpDocs);
      })
      .catch(() => {
        commit('SET_AdminDocs', []);
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profil.getAdmin.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  getServices({ dispatch, commit }) {
    dispatch('application/startLoading', null, { root: true });
    return api
      .findServices()
      .then((data) => {
        commit('SET_Services', data.content.edpAccessMems);
      })
      .catch(() => {
        commit('SET_Services', []);
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profil.service.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  update({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    return api
      .update(payload)
      .then((data) => {
        if (data.code === 0) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('profil.user.update'),
              type: 'success',
              time: 5,
              centered: true,
            },
            { root: true },
          );
          return data;
        }
        return Promise.reject(data);
      })
      .catch((data) => {
        if (Vue.$i18n.te(`response.code.${data.code}`)) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t(`response.code.${data.code}`),
              type: 'error',
              time: 5,
              centered: true,
            },
            { root: true },
          );
        } else {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('profile.user.update.error'),
              type: 'error',
              time: 5,
              centered: true,
            },
            { root: true },
          );
        }
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  updatePassword({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    return api
      .updatePassword(payload.newp, payload.actual)
      .then((data) => {
        if (data.code === 0) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('profil.password.update'),
              type: 'success',
              time: 5,
              centered: true,
            },
            { root: true },
          );
          return data;
        }
        return Promise.reject(data);
      })
      .catch((data) => {
        if (Vue.$i18n.te(`response.code.${data.code}`)) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t(`response.code.${data.code}`),
              type: 'error',
              time: 5,
              centered: true,
            },
            { root: true },
          );
        } else {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('profile.password.update.error'),
              type: 'error',
              time: 5,
              centered: true,
            },
            { root: true },
          );
        }
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  sendEmailValidationCode({ dispatch }, payload) {
    return api.sendEmailValidationCode(payload)
      .then((data) => {
        if (data.code !== 0) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('error.message.default'),
              type: 'error',
              time: 5,
              centered: true,
            },
            { root: true },
          );
          return Promise.reject(data);
        }
        return data;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error.response) {
          if (error.response.data.code === 140) {
            errorMessage = 'profile.validation.send.code.error.mail';
          } else if (error.response.data.code === 150) {
            errorMessage = 'profile.validation.send.code.limit';
          } else {
            errorMessage = 'error.message.default';
          }
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t(errorMessage),
              type: 'error',
              time: 10,
              centered: true,
            },
            { root: true },
          );
        }
        return Promise.reject(error);
      });
  },

  verifyEmailValidationCode({ dispatch }, payload) {
    return api.verifyEmailValidationCode(payload)
      .then((data) => {
        if (data.code !== 0) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('error.message.default'),
              type: 'error',
              time: 5,
              centered: true,
            },
            { root: true },
          );
          return Promise.reject(data);
        }
        return data;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error.response) {
          if (error.response.data.code === 110) {
            errorMessage = 'profile.validation.mail.code.expired';
          } else if (error.response.data.code === 120) {
            errorMessage = 'profile.validation.mail.code.wrong';
          } else if (error.response.data.code === 140) {
            errorMessage = 'profile.validation.mail.code.attempt.limit';
          } else {
            errorMessage = 'error.message.default';
          }
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t(errorMessage),
              type: 'error',
              time: 5,
              centered: true,
            },
            { root: true },
          );
        }
        return Promise.reject(error);
      });
  },

  updateEmail({ dispatch }, payload) {
    return new Promise((resolve) => {
      api.updateEmail(payload.email, payload.password)
        .then((data) => {
          if (data.code === 0) { return resolve(data); } return Promise.reject(data);
        })
        .catch((data) => {
          if (Vue.$i18n.te(`response.code.${data.code}`)) {
            dispatch(
              'application/openSnackbar',
              {
                message: Vue.$i18n.t(`response.code.${data.code}`),
                type: 'error',
                time: 5,
                centered: true,
              },
              { root: true },
            );
          } else {
            dispatch(
              'application/openSnackbar',
              {
                message: Vue.$i18n.t('activate.indication.psswdCheck'),
                type: 'error',
                time: 5,
                centered: true,
              },
              { root: true },
            );
          }
        });
    });
  },

  loadFCAuthSub(state, payload) {
    return new Promise((resolve, reject) => {
      api.loadFCAuthSub(payload).then((data) => {
        if (data.code === 0) {
          return resolve(data);
        }
        return reject(data);
      });
    });
  },

  disableFranceConnectLink() {
    return new Promise((resolve) => {
      api.disableFranceConnectLink().then((data) => {
        if (data.code === 0) {
          return resolve(data);
        }
        return Promise.reject(data);
      });
    });
  },

  updateService({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    return api
      .updateService(payload.id, payload.siteName, payload.login, payload.password)
      .then((data) => {
        if (data.code === 0) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('profil.service.update'),
              type: 'success',
              time: 5,
              centered: true,
            },
            { root: true },
          );
          return data;
        }
        return Promise.reject(data);
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profile.service.update.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  deleteService({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });

    return api
      .deleteService(payload.id)
      .then((data) => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profil.service.delete'),
            type: 'success',
            time: 5,
            centered: true,
          },
          { root: true },
        );
        return data;
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profile.service.delete.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  addService({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    // eslint-disable-next-line no-console
    return api
      .addService(payload.siteName, payload.login, payload.password)
      .then((data) => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profil.service.add'),
            type: 'success',
            time: 5,
            centered: true,
          },
          { root: true },
        );
        return data;
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profile.service.add.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  // UserInfoApi.enablePaySlipDemat(this.Signature);
  enablePaySlipDemat({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });

    return api
      .enablePaySlipDemat(payload)
      .then((data) => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profile.update.dematSats'),
            type: 'success',
            time: 5,
            centered: true,
          },
          { root: true },
        );
        return data;
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profile.update.dematSats.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
  disablePaySlipDemat({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });

    return api
      .disablePaySlipDemat(payload)
      .then((data) => {
        if (data.code === 0) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('profile.update.dematSats'),
              time: 5,
              type: 'success',
              centered: true,
            },
            { root: true },
          );
          return data;
        }
        return Promise.reject(data);
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profile.update.dematSats.error'),
            time: 5,
            type: 'error',
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  }, // profile.signatureCode.retrieve
  // Vue.$i18n.t('profile.signatureCode.retrieve.error'),
  // profile.signatureCode.retrieve.error
  retrieveSignatureCode({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    return api
      .retrieveSignatureCode(payload.phone)
      .then((data) => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profile.signatureCode.retrieve'),
            type: 'success',
            time: 5,
            centered: true,
          },
          { root: true },
        );
        return data;
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profile.signatureCode.retrieve.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },

  exportSafebox({ dispatch }) {
    dispatch(
      'application/openSnackbar',
      {
        message: Vue.$i18n.t('profile.exportSafebox.warning'),
        time: 6,
        centered: true,
        type: 'info',
      },
      { root: true },
    );
    return api
      .exportSafebox()
      .then((data) => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profile.exportSafebox'),
            time: 5,
            centered: true,
            type: 'success',
          },
          { root: true },
        );
        downloadjs(data, `export-edocperso-${new Date().toISOString()}.zip`, 'application/zip');
        return data;
      })
      .catch(() => {
        dispatch(
          'application/openSnackbar',
          {
            message: Vue.$i18n.t('profile.exportSafebox.error'),
            type: 'error',
            time: 5,
            centered: true,
          },
          { root: true },
        );
      });
  },
  EmptyUser({ commit }) {
    commit('SET_USER', undefined);

    commit('SET_AdminDocs', undefined);

    commit('SET_Services', []);

    commit('SET_AdminDocID', '');

    commit('SET_PetalCounter', undefined);

    commit('SET_DematStatus', undefined);
    commit('SET_USAGE_DATAS', undefined);
  },
  updateCGU({ dispatch }, payload) {
    dispatch('application/startLoading', null, { root: true });
    return api
      .updateCGU(payload.agreedToLastCGU, payload.isAnAdult)
      .then((data) => {
        if (data.code === 0) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('profile.update.cgu'),
              type: 'success',
              time: 5,
              centered: true,
            },
            { root: true },
          );
          return data;
        }
        return Promise.reject(data);
      })
      .catch((data) => {
        if (Vue.$i18n.te(`response.code.${data.code}`)) {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t(`response.code.${data.code}`),
              type: 'error',
              time: 5,
              centered: true,
            },
            { root: true },
          );
        } else {
          dispatch(
            'application/openSnackbar',
            {
              message: Vue.$i18n.t('profile.user.update.error'),
              type: 'error',
              time: 5,
              centered: true,
            },
            { root: true },
          );
        }
      })
      .finally(() => {
        dispatch('application/finishLoading', null, { root: true });
      });
  },
};
